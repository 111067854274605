/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import OverlayInfobar from "../overlayInfobar/overlayInfobar";
import { useAlertMsg } from "../../utils/context/alertMessageContext";
import { TB_STATUS_APPROVE, TTAX_STATUS_OPEN } from "../../utils/config";
import {
  ADD_ADJUSTMENT_SUCCESS,
  APPROVE_SUCCESS_MSG,
  NO_PAY_SAVE_SUCCESS,
  REVIEW_APPROVE_SUCCESS_MSG,
  STAY_ADDED,
  TT_RELEASE_SUCCESS_MSG,
  TT_UNRELEASE_SUCCESS_MSG_END,
  TT_UNRELEASE_SUCCESS_MSG_MIDDLE,
  TT_UNRELEASE_SUCCESS_MSG_START,
} from "../../utils/constants";
import ErrorText from "../ErrorText";

export default function InfoBarCollection() {
  const { alertContextState, alertContextDispatch } = useAlertMsg();
  return (
    <div className="overlay-collection">
      <OverlayInfobar
        show={alertContextState?.CommNonComm?.show}
        variant={alertContextState?.CommNonComm?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "CommNonCommHide",
          });
        }}
      >
        {alertContextState?.CommNonComm?.message}
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.reviewApproveFailContext?.show}
        variant={alertContextState?.reviewApproveFailContext?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "reviewApproveFailHide",
          });
        }}
      >
        {alertContextState?.reviewApproveFailContext?.message}
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.reviewApproveSuccessContext?.show}
        variant={alertContextState?.reviewApproveSuccessContext?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "reviewApproveSuccessHide",
          });
        }}
      >
        <span>
          {" "}
          {alertContextState?.reviewApproveSuccessContext?.userAction ===
          TB_STATUS_APPROVE
            ? APPROVE_SUCCESS_MSG
            : REVIEW_APPROVE_SUCCESS_MSG}
        </span>
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.groupBatchListFailed?.show}
        variant={alertContextState?.groupBatchListFailed?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "groupBatchListFailedHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.groupScreenServerError?.show}
        variant={alertContextState?.groupScreenServerError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "groupScreenServerErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.groupTableInternalError?.show}
        variant={alertContextState?.groupTableInternalError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "groupTableInternalErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      {/*  Add Adjustment Success */}
      <OverlayInfobar
        show={alertContextState?.groupAddAdjSuccess?.show}
        variant={alertContextState?.groupAddAdjSuccess?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "groupAddAdjSuccessHide",
          });
        }}
      >
        <span>{ADD_ADJUSTMENT_SUCCESS}</span>
      </OverlayInfobar>

      {/* Start - Group Research & Audit screen */}
      <OverlayInfobar
        show={alertContextState?.groupResearchAuditServerError?.show}
        variant={alertContextState?.groupResearchAuditServerError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "groupResearchAuditServerErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.groupResearchAuditInternalError?.show}
        variant={alertContextState?.groupResearchAuditInternalError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "groupResearchAuditInternalErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      {/* End - Group Research & Audit screen */}

      {/* Start - Stay summery screen */}

      <OverlayInfobar
        show={alertContextState?.staySummeryServerError?.show}
        variant={alertContextState?.staySummeryServerError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "staySummeryServerErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.staySummeryBatchTableError?.show}
        variant={alertContextState?.staySummeryBatchTableError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "staySummeryBatchTableErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.staySummeryAddStaySuccess?.show}
        variant={alertContextState?.staySummeryAddStaySuccess?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "staySummeryAddStaySuccessHide",
          });
        }}
      >
        <span>{STAY_ADDED}</span>
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.staySummeryEditStaySuccess?.show}
        variant={alertContextState?.staySummeryEditStaySuccess?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "staySummeryEditStaySuccessHide",
          });
        }}
      >
        {alertContextState?.staySummeryEditStaySuccess?.message}
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.staySummeryNoPaySaveSuccess?.show}
        variant={alertContextState?.staySummeryNoPaySaveSuccess?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "staySummeryNoPaySaveSuccessHide",
          });
        }}
      >
        <span>
          {alertContextState?.staySummeryNoPaySaveSuccess?.message
            ? alertContextState?.staySummeryNoPaySaveSuccess?.message
            : NO_PAY_SAVE_SUCCESS}
        </span>
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.staySummeryNoPaySaveError?.show}
        variant={alertContextState?.staySummeryNoPaySaveError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "staySummeryNoPaySaveErrorHide",
          });
        }}
      >
        {alertContextState?.staySummeryNoPaySaveError?.message}
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.staySummeryDetailsInerenalError?.show}
        variant={alertContextState?.staySummeryDetailsInerenalError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "staySummeryDetailsInerenalErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      {/* End - Stay summery screen */}

      {/*  Start - Transient screen*/}
      <OverlayInfobar
        show={alertContextState?.transServerError?.show}
        variant={alertContextState?.transServerError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "transServerErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.transReviewApproveFail?.show}
        variant={alertContextState?.transReviewApproveFail?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "transReviewApproveFailHide",
          });
        }}
      >
        {alertContextState?.transReviewApproveFail?.message}
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.transReviewApproveSuccess.show}
        variant={alertContextState?.transReviewApproveSuccess.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "transReviewApproveSuccessHide",
          });
        }}
      >
        <span>
          {" "}
          {alertContextState?.transReviewApproveSuccess.actionStatus ===
          TB_STATUS_APPROVE
            ? APPROVE_SUCCESS_MSG
            : REVIEW_APPROVE_SUCCESS_MSG}
        </span>
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.transTableInternalError?.show}
        variant={alertContextState?.transTableInternalError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "transTableInternalErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      {/*  End - Transient screen*/}

      {/*  Start - Transient Tax screen*/}
      <OverlayInfobar
        show={alertContextState?.transTaxServerError?.show}
        variant={alertContextState?.transTaxServerError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "transTaxServerErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.transTaxUpdateSuccess?.show}
        variant={alertContextState?.transTaxUpdateSuccess?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "transTaxUpdateSuccessHide",
          });
        }}
      >
        {alertContextState?.transTaxUpdateSuccess?.selectedStatus ===
        TTAX_STATUS_OPEN ? (
          TT_RELEASE_SUCCESS_MSG
        ) : (
          <span>
            {TT_UNRELEASE_SUCCESS_MSG_START}
            <b>{TT_UNRELEASE_SUCCESS_MSG_MIDDLE}</b>
            {TT_UNRELEASE_SUCCESS_MSG_END}
          </span>
        )}
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.transTaxUpdateError?.show}
        variant={alertContextState?.transTaxUpdateError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "transTaxUpdateErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>

      <OverlayInfobar
        show={alertContextState?.transTaxTableError?.show}
        variant={alertContextState?.transTaxTableError?.variant}
        onCloseCallback={() => {
          alertContextDispatch({
            type: "transTaxTableErrorHide",
          });
        }}
      >
        <ErrorText />
      </OverlayInfobar>
      {/*  End - Transient Tax screen*/}
    </div>
  );
}
