import { useDispatch, useSelector } from "react-redux";
import FormStaySummary from "../../../components/formStaySummary/FormStaySummary";
import ModalDialog from "../../../components/modal/ModalDialog";
import "./EditStay.scss";
import {
  reviewEditStay,
  updateEditReviewStatus,
  updateIATAResponse,
  updateIataUpdateFlag,
  validateIATA,
} from "../../../store/feature/staysummary/staySummarySlice";
import {
  LABEL_ADJ_CODE,
  LABEL_ARRIVAL_DATE,
  LABEL_BOOKING_SOURCE,
  LABEL_CONFO_NUMBER,
  LABEL_CORPORATE_ID,
  LABEL_CURRENCY,
  LABEL_GUEST_NAME,
  LABEL_MARKET_CODE,
  LABEL_MARKET_SEGMENT,
  LABEL_NO_PAY_REASON,
  LABEL_RATE_PLAN,
  LABEL_ROOM_NIGTHS,
  LABEL_ROOM_RATE_TAX_AMT,
  SS_ERROR_MSG_GUESTNAME_VALIDATION,
  SS_ERROR_MSG_GUESTNAME_REQUIRED,
  SS_GUESTNAME_PLACEHOLDER,
  SS_ERROR_MSG_ARRIVAL_REQUIRED,
  SS_ERROR_MSG_MARKETSEGMENT_REQUIRED,
  SS_ERROR_MSG_ROOM_TAX_AMOUNT_REQUIRED,
  SS_ERROR_MSG_RATEPLAN_REQUIRED,
  SS_ERROR_MSG_MARKETCODE_REQUIRED,
  SS_ERROR_MSG_ROOMNIGHTS_REQUIRED,
  SS_ERROR_MSG_ROOMRATE_REQUIRED,
  SS_ERROR_MSG_ROOMNIGHTS_INVALID,
  ERROR_MSG_VALIDATION_ALLOWED_NUMBERS,
  SS_ERROR_MSG_ONLY_ALPHA_NUMERIC,
  SS_ERROR_MSG_BOOKING_SOURCE_REQUIRED,
  SS_ERROR_MSG_ROOMNIGHTS_ARRIVAL_REQUIRED,
  SS_ERROR_MSG_MARKETCODE_INVALID,
  STAY_ERROR_MAX_ROOM_RATE,
  STAY_ERROR_MAX_ROOM_NIGHTS_EXCEEDED,
  STAY_ERROR_MAX_NIGHTS,
  LABEL_BOOKING_DATE,
  EDIT_STAY_DETAILS,
  TRAVEL_AGENCY_NAME,
  SS_FILTER_TITLE_MOD,
  TABLE_HEADER_IATA,
  VIEW_STAY_DETAILS,
  TT_TABLE_HEADER_REVENUE,
  TABLE_HEADER_PROPERTY_AMOUNT,
  SS_FILTER_TITLE_FOLIO,
  SS_FILTER_TITLE_INVOICE_INDICATOR,
  LABEL_CONTIGUOUS_INDICATOR,
  LABEL_PROCESSED,
  LABEL_COMMISSION_RATE,
  LABEL_COMMISSION_TAX_RATE,
  LABEL_COMMISSION_TAX,
  LABEL_SHARE_INDICATOR,
  LABEL_ROOM_RATE_ADR,
  VOID_STAY_NOPAYREASON_CONFO_MSG,
  STAY_REVIEW_BTN_LABEL,
  SS_ERROR_MSG_CONFO_REQUIRED,
  SS_ERROR_MSG_CONFO_VALIDATION_ACRS,
  SS_ERROR_MSG_CONFO_VALIDATION_NO_ACRS,
  ERROR_ARRIVAL_BEFORE_ACRS,
  ERROR_ARRIVAL_AFTER_ACRS,
  ERROR_ROOMNIGHT_AFTER_ACRS,
  LABEL_ROOM_RATE_ADR_TAX_INC,
  SHARED_STAY_EDITED,
  SHARED_STAY_RATE_EDITED,
} from "../../../utils/constants";
import { useEffect, useMemo, useState } from "react";
import {
  convertObjToArray,
  differenceInDays,
  replaceAll,
  formatAmount,
  rateValidation,
  rateTaxValidation,
} from "../../../utils/utils";
import {
  NON_ACRS_DEFAULT_DATE,
  RESERVATION_CANCELED,
  VOID_RESERVATION,
  BLANK_KEY,
  US_COUNTRY_CODE,
  GROUP_ROUTE_PATH,
  YES_KEY,
  READONLY,
  STAY_SUMMERY_PS,
  GROUP_MARKET_SEGMENTS,
  TRANSIENT_MARKET_SEGMENTS,
} from "../../../utils/config";
import Prompt from "../../../components/prompt/Prompt";
import EditStayReview from "./EditStayReview";
import { useLocation } from "react-router-dom";
import InfoBar from "../../../components/infobar/InfoBar";

export default function EditStay({
  modalShow,
  handleEditStayClose,
  rowData,
  isGroupBlock,
  stayBatchDetail,
}) {
  const {
    iataUpdateFlag,
    propertyDetails,
    masterData,
    payloadForEditReview,
    editStayReviewStatus,
    editStayReviewMode,
  } = useSelector((state) => state.staySummary);
  const { pathname } = useLocation();
  const { userInfo } = useSelector((state) => state.landing);
  const [editSaveErrorMessage, setEditSaveErrorMessage] = useState("");
  const [editSaveAPIError, setEditSaveAPIError] = useState("");

  const [skipError, setSkipError] = useState(false);

  const [arrival, setArrival] = useState(rowData?.arrivalDate);
  const [marketPrefix, setMarketPrefix] = useState();
  const [noPayReason, setNoPayReason] = useState(rowData.noPayRsn);
  const [roomRateCtac, setRoomRateCtac] = useState(rowData.roomRate);
  const [roomRateTax, setRoomRateTax] = useState(rowData.roomRateTax);
  const [disableSave, setDisableSave] = useState(false);
  const [roomNight, setRoomNight] = useState(rowData.roomNights);
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  const maxNight = Number(propertyDetails?.maxNight);
  const { countryCode } = propertyDetails;
  const [enablePrompt, setEnablePrompt] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [modifiedStay, setModifiedStay] = useState(false);
  const [modifiedRate, setModifiedRate] = useState(false);

  useEffect(() => {
    const isSharedStay =
      rowData.shareIndicator === YES_KEY ||
      rowData.contiguosIndicator === YES_KEY;
    if (
      (rowData.arrivalDate &&
        rowData.arrivalDate !== arrival &&
        isSharedStay) ||
      (rowData.roomNights && rowData.roomNights !== roomNight && isSharedStay)
    ) {
      setModifiedStay(true);
    }
  }, [roomNight, arrival]);

  useEffect(() => {
    if (modifiedStay || modifiedRate) {
      document.querySelector(".modal").scrollTop = 0;
    }
  }, [modifiedStay, modifiedRate]);

  useEffect(() => {
    const isSharedStay = rowData.shareIndicator === YES_KEY;
    if (rowData.roomRate !== roomRateCtac && isSharedStay) {
      setModifiedRate(true);
    }
  }, [roomRateCtac]);

  let latestArrivalDate = "",
    latestRoomNight = "";
  const marketCodeKey = isGroupBlock
    ? GROUP_MARKET_SEGMENTS
    : TRANSIENT_MARKET_SEGMENTS;
  useEffect(() => {
    if (rowData) {
      dispatch(validateIATA(rowData.iata));
    }
    return () => {
      dispatch(updateIATAResponse({}));
      dispatch(updateIataUpdateFlag([false, ""]));
      dispatch(
        updateEditReviewStatus({
          maxRoomRateError: false,
          success: false,
          apiError: true,
          message: ``,
        })
      );
      setModifiedStay(false);
      setModifiedRate(false);
    };
  }, []);

  useEffect(() => {
    if (editStayReviewMode) {
      dispatch(
        updateEditReviewStatus({
          maxRoomRateError: false,
          success: false,
          apiError: true,
          message: ``,
        })
      );
    }
  }, [editStayReviewMode]);

  useEffect(() => {
    if (noPayReason && noPayReason === VOID_RESERVATION) {
      setEnablePrompt(true);
    }
  }, [noPayReason]);

  const noPayPromptAction = (action) => {
    if (!action) {
      const noPay = rowData.noPayRsn ? rowData.noPayRsn : BLANK_KEY;
      setNoPayReason(noPay);
    }
    setEnablePrompt(false);
  };

  useEffect(() => {
    setDisableSave(false);
    if (editStayReviewStatus.maxRoomRateError) {
      setEditSaveErrorMessage(STAY_ERROR_MAX_ROOM_RATE);
      setSkipError(true);
      setDisableSave(true);
    } else {
      setEditSaveErrorMessage("");
      setSkipError(false);
    }
    if (editStayReviewStatus.apiError) {
      setEditSaveAPIError(editStayReviewStatus.message);
      setDisableSave(true);
    }
  }, [editStayReviewStatus]);

  useEffect(() => {
    if (roomNight > masterData?.maxRoomNightsAllowed) {
      //setEditSaveErrorMessage(STAY_ERROR_MAX_ROOM_NIGHTS_EXCEEDED);
      setSkipError(true);
      //setDisableSave(true);
    } else if (roomNight > maxNight) {
      //setEditSaveErrorMessage(STAY_ERROR_MAX_NIGHTS);
      setSkipError(!Object.keys(validationErrors).length ? true : false);
      //setDisableSave(true);
    } else {
      //setEditSaveErrorMessage("");
      setSkipError(false);
      setDisableSave(false);
    }
  }, [roomNight, roomRateCtac]);

  useEffect(() => {
    const amountPrecision = masterData?.currencyAndPrecision?.propertyPrecision
      ? masterData.currencyAndPrecision.propertyPrecision
      : 0;
    setRoomRateCtac(rowData.roomRate);
  }, [rowData.roomRate]);

  useEffect(() => {
    setRoomRateTax(rowData.roomRateTax);
  }, [rowData.roomRateTax]);

  const hotelConversion = propertyDetails?.acrsConvDate
    ? new Date(propertyDetails?.acrsConvDate).toISOString().slice(0, 10)
    : "";

  const isNonConvertedHotel = (() => {
    if (hotelConversion) {
      const hotelConversionInt = replaceAll(hotelConversion, "-");
      if (hotelConversion === NON_ACRS_DEFAULT_DATE) {
        return true;
      } else if (rowData.arrivalDate) {
        const arrivalInt = replaceAll(rowData.arrivalDate, "-");
        if (Number(arrivalInt) < Number(hotelConversionInt)) {
          return true;
        }
      }
    }
    return false;
  })();

  const validateArrival = (value) => {
    let returnVal = true;
    const arrivalInitialDate = Number(
      replaceAll(new Date(rowData.arrivalDate).toISOString().slice(0, 10), "-")
    );
    const arrivalUpdated = Number(
      replaceAll(new Date(value).toISOString().slice(0, 10), "-")
    );
    const hotelConversion = Number(
      replaceAll(
        propertyDetails?.acrsConvDate
          ? new Date(propertyDetails?.acrsConvDate).toISOString().slice(0, 10)
          : "",
        "-"
      )
    );
    const arrivalDate = new Date(value);
    const endArrival = arrivalDate.setDate(
      arrivalDate.getDate() + Number(latestRoomNight || roomNight)
    );
    const endArrivalDate = Number(
      replaceAll(new Date(endArrival).toISOString().slice(0, 10), "-")
    );
    if (isNonConvertedHotel && arrivalUpdated >= hotelConversion) {
      // Non converted hotel
      if (propertyDetails?.acrsConvDate === NON_ACRS_DEFAULT_DATE) {
        returnVal = true;
      } else {
        returnVal = ERROR_ARRIVAL_BEFORE_ACRS;
      }
    } else if (
      isNonConvertedHotel &&
      arrivalUpdated < hotelConversion &&
      endArrivalDate > hotelConversion
    ) {
      returnVal = ERROR_ROOMNIGHT_AFTER_ACRS;
    } else if (
      hotelConversion !== NON_ACRS_DEFAULT_DATE &&
      arrivalInitialDate >= hotelConversion &&
      arrivalUpdated < hotelConversion
    ) {
      // Converted hotel
      returnVal = ERROR_ARRIVAL_AFTER_ACRS;
    } else if (!isNonConvertedHotel && arrivalUpdated < hotelConversion) {
      returnVal = ERROR_ARRIVAL_BEFORE_ACRS;
    }
    return returnVal;
  };

  const validateRoomNight = (value) => {
    const pattern = /^[0-9]{1,4}$/g;
    let returnVal = true;
    if (pattern.test(value)) {
      // if (maxNight && Number(value) > maxNight) {
      //   returnVal = SS_ERROR_MSG_ROOMNIGHTS_MAXNIGHTS_VALIDATION;
      // } else {
      if (noPayReason && noPayReason === RESERVATION_CANCELED) {
        returnVal = true;
      } else if (Number(value) > masterData?.maxRoomNightsAllowed) {
        returnVal = STAY_ERROR_MAX_ROOM_NIGHTS_EXCEEDED;
      } else if (Number(value) > maxNight) {
        returnVal = STAY_ERROR_MAX_NIGHTS;
      } else {
        if (arrival) {
          const today = new Date();
          const todayMinusDay = new Date(today.setDate(today.getDate() - 1));
          const dayDif = differenceInDays(arrival, todayMinusDay);
          if (value <= dayDif) {
            const arrivalInitialDate = Number(
              replaceAll(
                new Date(rowData.arrivalDate).toISOString().slice(0, 10),
                "-"
              )
            );
            const arrivalDate = new Date(latestArrivalDate || arrival);
            const arrivalToendDate = new Date(latestArrivalDate || arrival);
            const endArrival = arrivalToendDate.setDate(
              arrivalToendDate.getDate() + Number(value)
            );
            const startArrivalDate = Number(
              replaceAll(arrivalDate.toISOString().slice(0, 10), "-")
            );
            const endArrivalDate = Number(
              replaceAll(new Date(endArrival).toISOString().slice(0, 10), "-")
            );
            const hotelConversion = Number(
              replaceAll(
                propertyDetails?.acrsConvDate
                  ? new Date(propertyDetails?.acrsConvDate)
                      .toISOString()
                      .slice(0, 10)
                  : "",
                "-"
              )
            );
            if (
              isNonConvertedHotel &&
              startArrivalDate < hotelConversion &&
              endArrivalDate > hotelConversion
            ) {
              // Non converted hotel
              if (propertyDetails?.acrsConvDate === NON_ACRS_DEFAULT_DATE) {
                returnVal = true;
              } else {
                returnVal = ERROR_ARRIVAL_BEFORE_ACRS;
              }
            } else {
              returnVal = true;
            }
          } else {
            returnVal = SS_ERROR_MSG_ROOMNIGHTS_INVALID;
          }
        } else {
          returnVal = SS_ERROR_MSG_ROOMNIGHTS_ARRIVAL_REQUIRED;
        }
      }
      //}
    } else {
      returnVal = ERROR_MSG_VALIDATION_ALLOWED_NUMBERS;
    }
    return returnVal;
  };

  function findLargestDate(...args) {
    return new Date(Math.max(...args));
  }

  const config = useMemo(() => {
    let noPayReasonsList = { ...masterData.noPayReasons };
    if (!(userInfo?.ipc || userInfo?.admin)) {
      delete noPayReasonsList.VOID;
    }
    if (!isGroupBlock) {
      // Removed FSPD for transient
      delete noPayReasonsList.FSPD;
    } else if (stayBatchDetail?.fastPayInd === YES_KEY) {
      noPayReasonsList = {
        FSPD: masterData.noPayReasons.FSPD,
        CXLD: masterData.noPayReasons.CXLD,
        NSHW: masterData.noPayReasons.NSHW,
        COMP: masterData.noPayReasons.COMP,
        NCMS: masterData.noPayReasons.NCMS,
      };
    }
    if (rowData.adjRsn === "NMNT") {
      noPayReasonsList = {
        BLANK: "",
        NCMS: masterData.noPayReasons.NCMS,
        CXLD: masterData.noPayReasons.CXLD,
        NSHW: masterData.noPayReasons.NSHW,
      };
    }
    const hotelConversionData = propertyDetails?.acrsConvDate
      ? new Date(propertyDetails?.acrsConvDate)
      : 0;
    const hotelConversionMinusDay = new Date(
      hotelConversionData.setDate(hotelConversionData.getDate() - 1)
    );
    const bookingDate = rowData.bookingDate ? new Date(rowData.bookingDate) : 0;
    const isConvertedHotel =
      hotelConversion === NON_ACRS_DEFAULT_DATE ||
      new Date(arrival) < hotelConversionMinusDay
        ? false
        : true;
    const today = new Date();
    const todayMinusDay = new Date(today.setDate(today.getDate() - 1));
    const maxDate = isConvertedHotel
      ? todayMinusDay.toISOString().slice(0, 10)
      : todayMinusDay.toISOString().slice(0, 10);
    const todayMinusYear = new Date(today.setFullYear(today.getFullYear() - 1));
    const arrivalOriginalDate = new Date(rowData.arrivalDate);
    const arrivalMinusYear = new Date(
      arrivalOriginalDate.setFullYear(arrivalOriginalDate.getFullYear() - 1)
    );
    const minDate = (function () {
      return isConvertedHotel
        ? findLargestDate(bookingDate, todayMinusYear)
            .toISOString()
            .slice(0, 10)
        : bookingDate
        ? new Date(bookingDate).toISOString().slice(0, 10)
        : new Date(arrivalMinusYear).toISOString().slice(0, 10);
    })();

    const marketSegments = !masterData?.[marketCodeKey][rowData.marketSegmentId]
      ? {
          ...masterData?.[marketCodeKey],
          [rowData.marketSegmentId]: rowData.marketSegmentId,
        }
      : { ...masterData[marketCodeKey] };
    const marketSegOptions = convertObjToArray(marketSegments).sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    const marketSegmentDisplayVal = () => {
      let segment = "";
      if (marketPrefix) {
        if (masterData[marketCodeKey][marketPrefix]) {
          segment = masterData[marketCodeKey][marketPrefix];
        } else {
          segment = marketPrefix;
        }
      } else if (rowData?.marketSegmentId) {
        if (masterData[marketCodeKey][rowData.marketSegmentId]) {
          segment = masterData[marketCodeKey][rowData.marketSegmentId];
        } else {
          segment = rowData.marketSegmentId;
        }
      }
      return segment;
    };

    const disableTaxAmount = (() => {
      let returnVal = false;
      if (isNonConvertedHotel) {
        if (
          countryCode === US_COUNTRY_CODE ||
          propertyDetails.roomRateIndicator === "I" ||
          propertyDetails.roomRateIndicator === "E"
        ) {
          returnVal = true;
        }
      } else if (rowData.taxIndicator === "N") {
        returnVal = true;
      }
      return returnVal;
    })();

    const amountPrecision = masterData?.currencyAndPrecision?.propertyPrecision
      ? masterData.currencyAndPrecision.propertyPrecision
      : 0;
    const roomRate = rowData.roomRateTax
      ? (
          Number(rowData.roomRate.replace(/,/g, "")) +
          Number(rowData.roomRateTax.replace(/,/g, ""))
        ).toFixed(amountPrecision)
      : rowData.roomRate;

    const getFieldType = (actualType, fieldName) => {
      let returnData = "";
      const insideBlockEditable = [
        "arrivalDate",
        "roomNights",
        "roomRateCtac",
        "noPayReason",
        "adjustCode",
        "roomRateTax",
      ];
      const outsideBlockEditable = [
        "roomNights",
        "roomRateCtac",
        "roomRateTax",
      ];
      if (rowData.enableEdit) {
        if (isGroupBlock) {
          if (rowData.outsideBlankInd === YES_KEY) {
            returnData = outsideBlockEditable.includes(fieldName)
              ? actualType
              : "readonly";
          } else {
            returnData = insideBlockEditable.includes(fieldName)
              ? actualType
              : "readonly";
          }
        } else {
          returnData = actualType;
        }
      } else {
        returnData = "readonly";
      }
      return returnData;
    };
    const guestNameType = getFieldType("text", "guestName");
    const arrivalDateType = getFieldType("date", "arrivalDate");
    const roomNightsType = getFieldType("text", "roomNights");
    const roomRateCtacType = getFieldType("text", "roomRateCtac");
    const roomRateTaxType = getFieldType("text", "roomRateTax");
    const marketPrefixType = getFieldType("selectv2", "marketPrefix");
    const ratePlanType = getFieldType("text", "ratePlan");
    const corporateIdType = getFieldType("text", "corporateId");
    const marketCodeType = getFieldType("text", "marketCode");
    const noPayReasonType = getFieldType("selectv2", "noPayReason");
    const bookingSourceType = getFieldType("text", "bookingSource");

    const validationRequired =
      rowData.outsideBlankInd === YES_KEY ? false : true;

    const roomRateTaxIsValid =
      countryCode === US_COUNTRY_CODE ||
      isNonConvertedHotel ||
      roomRateTaxType === READONLY ||
      (noPayReason === VOID_RESERVATION && !validationRequired);
    let configData = {
      form: {
        iataNumber: {
          label: TABLE_HEADER_IATA,
          type: "readonly",
          defaultValue: rowData.iata,
          fieldClassName: "mb-8",
          width: 200,
        },
        agencyName: {
          label: TRAVEL_AGENCY_NAME,
          type: "readonly",
          defaultValue: rowData.taName,
          fieldClassName: "mb-8",
          width: 305,
        },
        modIndicator: {
          label: SS_FILTER_TITLE_MOD,
          type: "readonly",
          defaultValue: rowData.modIndicator,
          fieldClassName: "mb-8",
          width: 200,
        },
        confirmationNumber: {
          label: LABEL_CONFO_NUMBER,
          type: rowData.adjRsn === "MADD" ? "text" : "readonly",
          defaultValue: rowData.confirmationNumber,
          width: 200,
          maxLength: 10,
          className: "uppercase",
          rules: {
            required: rowData.adjRsn === "MADD" ? true : false,
            message: SS_ERROR_MSG_CONFO_REQUIRED,
            validate:
              rowData.adjRsn === "MADD"
                ? (value) => {
                    const pattEightChar = /^[0-9a-zA-Z]{8,8}$/g;
                    const pattTenChar = /^[0-9a-zA-Z]{10,10}$/g;
                    if (isNonConvertedHotel) {
                      return pattEightChar.test(value)
                        ? true
                        : SS_ERROR_MSG_CONFO_VALIDATION_ACRS;
                    } else {
                      return pattTenChar.test(value)
                        ? true
                        : SS_ERROR_MSG_CONFO_VALIDATION_NO_ACRS;
                    }
                  }
                : () => {},
          },
        },
        guestName: {
          label: LABEL_GUEST_NAME,
          type: guestNameType,
          className: "uppercase",
          placeholder: SS_GUESTNAME_PLACEHOLDER,
          maxLength: 20,
          defaultValue: rowData.guestName,
          width: 305,
          rules: {
            required: guestNameType === READONLY ? false : true,
            message: SS_ERROR_MSG_GUESTNAME_REQUIRED,
            validate:
              guestNameType === READONLY
                ? () => {}
                : (value) => {
                    if (value.length < 20) {
                      return value.includes("/")
                        ? true
                        : SS_ERROR_MSG_GUESTNAME_VALIDATION;
                    } else {
                      return true;
                    }
                  },
          },
        },
        arrivalDate: {
          label: LABEL_ARRIVAL_DATE,
          type: arrivalDateType,
          maxDate,
          minDate,
          defaultValue: arrival,
          width: 218,
          rules: {
            required: arrivalDateType === READONLY ? false : true,
            message: SS_ERROR_MSG_ARRIVAL_REQUIRED,
            validate:
              arrivalDateType === READONLY
                ? () => {}
                : (value) => {
                    setArrival(value);
                    latestArrivalDate = value;
                    return validateArrival(value);
                  },
          },
        },
        hr: {
          type: "hr",
        },
        roomNights: {
          label: LABEL_ROOM_NIGTHS,
          type: roomNightsType,
          maxLength: 4,
          defaultValue: rowData.roomNights,
          width: 185,
          rules: {
            required:
              roomNightsType === READONLY || noPayReason === VOID_RESERVATION
                ? false
                : true,
            message: SS_ERROR_MSG_ROOMNIGHTS_REQUIRED,
            validate:
              roomNightsType === READONLY || noPayReason === VOID_RESERVATION
                ? () => {}
                : (value) => {
                    setRoomNight(Number(value));
                    latestRoomNight = value;
                    return validateRoomNight(value);
                  },
          },
        },
        roomRateCtac: {
          label:
            (isNonConvertedHotel &&
              propertyDetails.roomRateIndicator === "I") ||
            (!isNonConvertedHotel && rowData.taxIndicator === "Y")
              ? LABEL_ROOM_RATE_ADR_TAX_INC
              : LABEL_ROOM_RATE_ADR,
          type: roomRateCtacType,
          defaultValue: roomRateCtac,
          width: 205,
          precision: masterData?.currencyAndPrecision?.propertyPrecision,
          rules: {
            required:
              roomRateCtacType === READONLY || noPayReason === VOID_RESERVATION
                ? false
                : true,
            message: SS_ERROR_MSG_ROOMRATE_REQUIRED,
            validate:
              roomRateCtacType === READONLY || noPayReason === VOID_RESERVATION
                ? () => {}
                : (value) => {
                    return rateValidation(value);
                  },
          },
          onBlur: (val) => {
            const formatedValue = val.replace(/,/g, "");
            if (!isNaN(Number(formatedValue))) {
              let rate = formatAmount(
                formatedValue,
                masterData?.currencyAndPrecision?.propertyPrecision
              );
              setRoomRateCtac(rate);
            }
          },
          onKeyup: (val) => {
            setRoomRateCtac(val);
          },
        },
        roomRateTax: {
          label: LABEL_ROOM_RATE_TAX_AMT,
          type: roomRateTaxType,
          defaultValue: roomRateTax,
          width: 210,
          disabled: disableTaxAmount,
          precision: masterData?.currencyAndPrecision?.propertyPrecision,
          rules: {
            required: disableTaxAmount || roomRateTaxIsValid ? false : true,
            message: SS_ERROR_MSG_ROOM_TAX_AMOUNT_REQUIRED,
            validate:
              disableTaxAmount || roomRateTaxIsValid
                ? () => {}
                : (value) => {
                    return rateTaxValidation(
                      value,
                      roomRateCtac,
                      disableTaxAmount || roomRateTaxIsValid ? false : true
                    );
                  },
          },
          onBlur: (val) => {
            const formatedValue = val.replace(/,/g, "");
            if (!isNaN(Number(formatedValue))) {
              let rate = formatAmount(
                formatedValue,
                masterData?.currencyAndPrecision?.propertyPrecision
              );
              setRoomRateTax(rate);
            }
          },
          onKeyup: (val) => {
            setRoomRateTax(val);
          },
        },
        propertyCurrency: {
          label: LABEL_CURRENCY,
          defaultValue: masterData?.currencyAndPrecision?.propertyCurrency,
          type: "readonly",
          width: 90,
        },
        marketPrefix: {
          label: LABEL_MARKET_SEGMENT,
          placeholder: "Select",
          type: marketPrefixType,
          options: marketSegOptions,
          displayValue: isConvertedHotel ? marketSegmentDisplayVal() : "",
          defaultValue: isConvertedHotel ? rowData?.marketSegmentId : "",
          fieldClassName: "full-width",
          width: 235,
          rules: {
            required: marketPrefixType === READONLY ? false : true,
            message: SS_ERROR_MSG_MARKETSEGMENT_REQUIRED,
            validate:
              marketPrefixType === READONLY
                ? () => {}
                : (value) => {
                    if (value?.length) {
                      setMarketPrefix(value);
                    } else {
                      setMarketPrefix("");
                    }
                  },
          },
        },
        ratePlan: {
          label: LABEL_RATE_PLAN,
          className: "uppercase",
          type: ratePlanType,
          defaultValue: rowData.ratePlan || "",
          width: 235,
          rules: {
            required: ratePlanType === READONLY ? false : true,
            message: SS_ERROR_MSG_RATEPLAN_REQUIRED,
            validate:
              ratePlanType === READONLY
                ? () => {}
                : (value) => {
                    const pattern = /^[a-zA-Z0-9]*$/g;
                    return pattern.test(value)
                      ? true
                      : SS_ERROR_MSG_ONLY_ALPHA_NUMERIC;
                  },
          },
        },
        corporateId: {
          label: LABEL_CORPORATE_ID,
          className: "uppercase",
          type: corporateIdType,
          defaultValue: rowData.corporateId || "",
          width: 235,
          rules: {
            required: false,
            validate: (value) => {
              const pattern = /^[a-zA-Z0-9]*$/g;
              return value.length
                ? pattern.test(value)
                  ? true
                  : SS_ERROR_MSG_ONLY_ALPHA_NUMERIC
                : true;
            },
          },
        },
        marketCode: {
          label: LABEL_MARKET_CODE,
          className: "uppercase",
          type: marketCodeType,
          defaultValue: isConvertedHotel ? "" : rowData?.marketSegmentId,
          maxLength: 8,
          width: 215,
          rules: {
            required: marketCodeType === READONLY ? false : true,
            message: SS_ERROR_MSG_MARKETCODE_REQUIRED,
            validate:
              marketCodeType === READONLY
                ? () => {}
                : (value) => {
                    if (!isGroupBlock) {
                      const code = value.slice(0, 2).toUpperCase();
                      if (masterData[marketCodeKey][code]) {
                        return true;
                      } else {
                        return SS_ERROR_MSG_MARKETCODE_INVALID;
                      }
                    }
                  },
          },
        },
        noPayReason: {
          label: LABEL_NO_PAY_REASON,
          className: "uppercase",
          type: noPayReasonType,
          options: convertObjToArray(noPayReasonsList, "keyValue", true),
          defaultValue: noPayReason,
          width: 255,
          rules: {
            validate:
              noPayReasonType === READONLY
                ? () => {}
                : (value) => {
                    setNoPayReason(value);
                  },
          },
        },
        adjustCode: {
          label: LABEL_ADJ_CODE,
          type: "readonly",
          //type: rowData.enableEdit ? "selectv2" : "readonly",
          //options: convertObjToArray(masterData?.adjustmentCodes, "keyValue"),
          defaultValue: rowData.adjRsn,
          width: 235,
        },
        roomRevenue: {
          label: TT_TABLE_HEADER_REVENUE.toUpperCase(),
          type: "readonly",
          defaultValue: rowData.roomRevenue,
          fieldClassName: "mb-8",
          width: 215,
        },
        propertyAmount: {
          label: TABLE_HEADER_PROPERTY_AMOUNT.toUpperCase(),
          type: "readonly",
          defaultValue: rowData.propertyAmount,
          fieldClassName: "mb-8",
          width: 255,
        },
        commissionTax: {
          label: LABEL_COMMISSION_TAX,
          type: "readonly",
          defaultValue: rowData.commTax,
          fieldClassName: "mb-8",
          width: 235,
        },
        folioIndicator: {
          label: SS_FILTER_TITLE_FOLIO,
          type: "readonly",
          defaultValue: rowData.folioIndicator,
          fieldClassName: "mb-8",
          width: 215,
        },
        commissionRate: {
          label: LABEL_COMMISSION_RATE,
          type: "readonly",
          defaultValue: rowData.propertyRatePercentage,
          fieldClassName: "mb-8",
          width: 255,
        },
        commissionTaxRate: {
          label: LABEL_COMMISSION_TAX_RATE,
          type: "readonly",
          defaultValue: rowData.commTaxPercentage,
          fieldClassName: "mb-8",
          width: 235,
        },
        psIndicator: {
          label: LABEL_PROCESSED,
          type: "readonly",
          displayValue: rowData?.psIndicator === STAY_SUMMERY_PS ? YES_KEY : "",
          defaultValue: rowData.psIndicator || "",
          fieldClassName: "mb-8",
          width: 125,
        },
        shareIndicator: {
          label: LABEL_SHARE_INDICATOR,
          type: "readonly",
          defaultValue: rowData.shareIndicator,
          fieldClassName: "mb-8",
          width: 172,
        },
        contiguosIndicator: {
          label: LABEL_CONTIGUOUS_INDICATOR,
          type: "readonly",
          defaultValue: rowData.contiguosIndicator,
          fieldClassName: "mb-8",
          width: 220,
        },
        invoiceIndicator: {
          label: SS_FILTER_TITLE_INVOICE_INDICATOR,
          type: "readonly",
          defaultValue: rowData.wsInvInd,
          fieldClassName: "mb-8",
          width: 172,
        },
        bookingDate: {
          label: LABEL_BOOKING_DATE,
          type: "readonly",
          defaultValue: rowData.bookingDate,
          width: 125,
        },
        bookingSource: {
          label: LABEL_BOOKING_SOURCE,
          className: "uppercase",
          defaultValue: rowData.bookingSource,
          type: bookingSourceType,
          maxLength: 5,
          width: 172,
          rules: {
            required: bookingSourceType === READONLY ? false : true,
            message: SS_ERROR_MSG_BOOKING_SOURCE_REQUIRED,
            validate:
              bookingSourceType === READONLY
                ? () => {}
                : (value) => {
                    const pattern = /^[a-zA-Z0-9]{1,5}$/g;
                    return pattern.test(value)
                      ? true
                      : SS_ERROR_MSG_ONLY_ALPHA_NUMERIC;
                  },
          },
        },
      },
    };

    if (arrival && hotelConversion && !isGroupBlock) {
      const arrivalInt = replaceAll(latestArrivalDate, "-");
      const arrivalInitialDate = Number(
        replaceAll(
          new Date(rowData.arrivalDate).toISOString().slice(0, 10),
          "-"
        )
      );
      const hotelConversionInt = replaceAll(hotelConversion, "-");
      if (
        hotelConversion === NON_ACRS_DEFAULT_DATE ||
        Number(arrivalInitialDate) < Number(hotelConversionInt)
      ) {
        configData.form.marketPrefix.disabled = true;
        configData.form.ratePlan.disabled = true;
        configData.form.corporateId.disabled = true;

        configData.form.marketPrefix.rules.required = false;
        configData.form.marketPrefix.rules.validate = () => {};
        configData.form.ratePlan.rules.required = false;
        configData.form.ratePlan.rules.validate = () => {};

        configData.form.marketCode.disabled = false;
        configData.form.marketCode.rules.required =
          validationRequired || marketCodeType === READONLY ? false : true;
        if (!validationRequired) {
          configData.form.marketCode.rules.validate = () => {};
        }
      } else {
        configData.form.marketPrefix.disabled = false;
        configData.form.ratePlan.disabled = false;
        configData.form.corporateId.disabled = false;

        configData.form.marketPrefix.rules.required = validationRequired;
        configData.form.ratePlan.rules.required = validationRequired;
        if (!validationRequired) {
          configData.form.marketPrefix.rules.validate = () => {};
          configData.form.ratePlan.rules.validate = () => {};
        }

        configData.form.marketCode.disabled = true;
        configData.form.marketCode.rules.required = false;
        configData.form.marketCode.rules.validate = () => {};
      }
    }

    return configData;
  }, [
    arrival,
    marketPrefix,
    noPayReason,
    roomRateCtac,
    roomRateTax,
    rowData,
    masterData,
    propertyDetails,
  ]);

  const handleSubmit = (data) => {
    if (data.corporateId) {
      data.corporateId = data.corporateId.toUpperCase();
    }
    if (data.guestName) {
      data.guestName = data.guestName.toUpperCase();
    }
    if (data.roomRateCtac) {
      data.roomRateCtac = formatAmount(
        data.roomRateCtac,
        masterData?.currencyAndPrecision?.propertyPrecision
      );
    }
    if (data.roomRateTax) {
      data.roomRateTax = formatAmount(
        data.roomRateTax,
        masterData?.currencyAndPrecision?.propertyPrecision
      );
    }

    if (data.bookingSource) {
      data.bookingSource = data.bookingSource.toUpperCase();
    }
    if (data.marketPrefix) {
      data.marketPrefix = data.marketPrefix.toUpperCase();
    }
    if (data.ratePlan) {
      data.ratePlan = data.ratePlan.toUpperCase();
    }
    if (data.marketCode) {
      data.marketCode = data.marketCode.toUpperCase();
    }
    dispatch(
      reviewEditStay({
        ...payloadForEditReview,
        ...data,
        ...{
          bookedCurrency: masterData?.currencyAndPrecision?.bookedCurrency,
          bookedPrecision: masterData?.currencyAndPrecision?.bookedPrecision,
          propertyPrecision:
            masterData?.currencyAndPrecision?.propertyPrecision,
          skipError: skipError,
          sequenceNumber: rowData.sequenceNumber,
          invoiceIndicator: rowData.wsInvInd,
          bookingDate: rowData.bookingDate,
          transientStay: pathname.includes(GROUP_ROUTE_PATH) ? false : true,
          stayAction: 2,
          userId: userInfo?.EID,
          userInitials: userInfo?.userInitials,
        },
      })
    );
  };

  const handleErrors = (errors) => {
    setValidationErrors(errors);
  };

  const handleIsDirty = (flag) => {
    setIsDirty(flag);
  };

  return (
    <>
      <ModalDialog
        show={modalShow}
        handleOnShowClose={handleEditStayClose}
        size={"lg"}
        ariaLabelledby="contained-modal-title-vcenter"
        className={`stay-overlay overlay-fixed-min-width ${
          iataUpdateFlag ? "overlay-fixed-min-width" : "overlay-auto-min-width"
        }`}
      >
        <div className="edit-stay">
          <div className={editStayReviewMode ? "showform" : "hideform"}>
            <EditStayReview
              rowData={rowData}
              handleEditStayClose={handleEditStayClose}
            />
          </div>
          <div className={!editStayReviewMode ? "showform" : "hideform"}>
            <h4 className="weight-700">
              {rowData.enableEdit ? EDIT_STAY_DETAILS : VIEW_STAY_DETAILS}
            </h4>
            {modifiedStay ? (
              <InfoBar variant="warning" enableTimerClose={false}>
                {SHARED_STAY_EDITED}
              </InfoBar>
            ) : null}
            {modifiedRate ? (
              <InfoBar variant="warning" enableTimerClose={false}>
                {SHARED_STAY_RATE_EDITED}
              </InfoBar>
            ) : null}

            <FormStaySummary
              config={config}
              submitCallBack={handleSubmit}
              ignoreEmpty={false}
              handleAddStayClose={handleEditStayClose}
              disableSubmitAction={disableSave}
              applyBtnTitle={STAY_REVIEW_BTN_LABEL}
              className={`modify-form-stay step-2-stay p-0`}
              mode="onChange"
              alertMessage={editSaveErrorMessage}
              errorMessage={editSaveAPIError}
              formErrorCallback={handleErrors}
              isDirtyCallback={handleIsDirty}
              readOnly={!rowData.enableEdit}
              acrs={isNonConvertedHotel}
              type={"editstay"}
              extraParams={{ isGroupBlock }}
            />
          </div>
        </div>
      </ModalDialog>
      <Prompt
        show={enablePrompt}
        modalBody={VOID_STAY_NOPAYREASON_CONFO_MSG}
        actionCallBack={noPayPromptAction}
        backdropClassName={"void_stay-no-shadow"}
      />
    </>
  );
}
