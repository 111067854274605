import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormStaySummary from "../../../components/formStaySummary/FormStaySummary";
import {
  enableEditStayReviewMode,
  saveEditStay,
  updateEditSaveStatus,
} from "../../../store/feature/staysummary/staySummarySlice";
import {
  LABEL_ADJ_CODE,
  LABEL_ARRIVAL_DATE,
  LABEL_BOOKING_SOURCE,
  LABEL_CONFO_NUMBER,
  LABEL_CORPORATE_ID,
  LABEL_CURRENCY,
  LABEL_GUEST_NAME,
  LABEL_MARKET_CODE,
  LABEL_MARKET_SEGMENT,
  LABEL_NO_PAY_REASON,
  LABEL_RATE_PLAN,
  LABEL_ROOM_NIGTHS,
  SS_GUESTNAME_PLACEHOLDER,
  STAY_SAVE_BTN_LABEL,
  LABEL_BOOKING_DATE,
  LABEL_ROOM_RATE_ADR,
  TABLE_HEADER_IATA,
  TRAVEL_AGENCY_NAME,
  SS_FILTER_TITLE_MOD,
  TT_TABLE_HEADER_REVENUE,
  TABLE_HEADER_PROPERTY_AMOUNT,
  LABEL_COMMISSION_TAX,
  SS_FILTER_TITLE_FOLIO,
  LABEL_COMMISSION_RATE,
  LABEL_COMMISSION_TAX_RATE,
  LABEL_PROCESSED,
  LABEL_SHARE_INDICATOR,
  LABEL_CONTIGUOUS_INDICATOR,
  SS_FILTER_TITLE_INVOICE_INDICATOR,
  STAY_ERROR_MAX_ROOM_RATE,
  LABEL_ROOM_RATE_TAX_AMT,
  REVIEW_STAY_DETAILS,
  LABEL_ROOM_RATE_ADR_TAX_INC,
} from "../../../utils/constants";
import {
  GROUP_ROUTE_PATH,
  ADD_STAY_REVIEW_RESET,
  US_COUNTRY_CODE,
  STAY_SUMMERY_PS,
  YES_KEY,
  NON_ACRS_DEFAULT_DATE,
  GROUP_MARKET_SEGMENTS,
  TRANSIENT_MARKET_SEGMENTS,
} from "../../../utils/config";
import { useLocation } from "react-router-dom";
import { addToObject, replaceAll } from "../../../utils/utils";

export default function EditStayReview({ handleEditStayClose, rowData }) {
  const { pathname } = useLocation();
  const { userInfo } = useSelector((state) => state.landing);
  const {
    masterData,
    propertyDetails,
    payloadForEditSave,
    editStaySaveStatus,
    editStayReviewMode,
    editStayReviewStatus,
    editStayReviewStatus: {
      reviewStayActionDetails: {
        iataNumber,
        modIndicator,
        arrivalDate,
        guestName,
        confirmationNumber,
        roomNights,
        propertyCurrency,
        roomRate,
        roomRateTax,
        marketPrefix,
        ratePlan,
        corporateId,
        marketCode,
        noPayReason,
        grpUnpaidCode,
        adjustCode,
        roomRevenue,
        propertyAmount,
        commTax,
        folioIndicator,
        propertyRatePercentage,
        commTaxPercentage,
        psIndicator,
        shareIndicator,
        contiguosIndicator,
        invoiceIndicator,
        bookingDate,
        bookingSource,
        taName,
      },
    },
  } = useSelector((state) => state.staySummary);
  const { countryCode } = propertyDetails;

  const [editStaySaveErrorMessage, setEditStaySaveErrorMessage] = useState("");
  const [editStaySaveAPIError, setEditStaySaveAPIError] = useState("");
  const [disableSave, setDisableSave] = useState(false);
  const isGroupBlock = pathname.includes(GROUP_ROUTE_PATH);
  const marketCodeKey = isGroupBlock
    ? GROUP_MARKET_SEGMENTS
    : TRANSIENT_MARKET_SEGMENTS;

  useEffect(() => {
    if (editStaySaveStatus.maxRoomRateError) {
      setEditStaySaveErrorMessage(STAY_ERROR_MAX_ROOM_RATE);
    } else {
      setEditStaySaveErrorMessage("");
    }
    if (editStaySaveStatus.apiError) {
      setEditStaySaveAPIError(editStaySaveStatus.message);
    }
    setDisableSave(false);
  }, [editStaySaveStatus]);

  useEffect(() => {
    if (!editStayReviewMode) {
      dispatch(
        updateEditSaveStatus({
          ...ADD_STAY_REVIEW_RESET,
        })
      );
    }
  }, [editStayReviewMode]);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(enableEditStayReviewMode(false));
    };
  }, []);
  const hotelConversion = propertyDetails?.acrsConvDate
    ? new Date(propertyDetails?.acrsConvDate).toISOString().slice(0, 10)
    : "";

  const isNonConvertedHotel = (() => {
    if (hotelConversion) {
      const hotelConversionInt = replaceAll(hotelConversion, "-");
      if (hotelConversion === NON_ACRS_DEFAULT_DATE) {
        return true;
      } else if (arrivalDate) {
        const arrivalInt = replaceAll(arrivalDate, "-");
        if (Number(arrivalInt) < Number(hotelConversionInt)) {
          return true;
        }
      }
    }
    return false;
  })();

  const config = useMemo(() => {
    const isGroup = pathname.includes(GROUP_ROUTE_PATH) ? true : false;
    let configData = {
      form: {
        iataNumber: {
          label: TABLE_HEADER_IATA,
          type: "readonly",
          defaultValue: iataNumber,
          width: 200,
        },
        taName: {
          label: TRAVEL_AGENCY_NAME,
          type: "readonly",
          defaultValue: taName,
          width: 305,
        },
        modIndicator: {
          label: SS_FILTER_TITLE_MOD,
          type: "readonly",
          defaultValue: modIndicator,
          width: 200,
        },
        confirmationNumber: {
          label: LABEL_CONFO_NUMBER,
          type: "readonly",
          defaultValue: confirmationNumber,
          width: 200,
        },
        guestName: {
          label: LABEL_GUEST_NAME,
          type: "readonly",
          className: "uppercase",
          placeholder: SS_GUESTNAME_PLACEHOLDER,
          defaultValue: guestName,
          width: 305,
        },
        arrivalDate: {
          label: LABEL_ARRIVAL_DATE,
          type: "readonly",
          defaultValue: arrivalDate,
          width: 200,
        },
        hr: {
          type: "hr",
        },
        roomNights: {
          label: LABEL_ROOM_NIGTHS,
          type: "readonly",
          defaultValue: roomNights,
          width: 185,
        },
        roomRate: {
          label:
            (isNonConvertedHotel &&
              propertyDetails.roomRateIndicator === "I") ||
            (!isNonConvertedHotel && rowData?.taxIndicator === "Y")
              ? LABEL_ROOM_RATE_ADR_TAX_INC
              : LABEL_ROOM_RATE_ADR,
          type: "readonly",
          defaultValue: roomRate,
          width: 205,
        },
        propertyCurrency: {
          label: LABEL_CURRENCY,
          defaultValue: propertyCurrency,
          type: "readonly",
          width: 90,
        },
        marketPrefix: {
          label: LABEL_MARKET_SEGMENT,
          type: "readonly",
          defaultValue: marketPrefix?.length
            ? masterData[marketCodeKey][marketPrefix]
            : "",
          fieldClassName: "full-width",
          width: 235,
        },
        ratePlan: {
          label: LABEL_RATE_PLAN,
          className: "uppercase",
          type: "readonly",
          defaultValue: ratePlan,
          width: 235,
        },
        corporateId: {
          label: LABEL_CORPORATE_ID,
          className: "uppercase",
          type: "readonly",
          defaultValue: corporateId,
          width: 235,
        },
        marketCode: {
          label: LABEL_MARKET_CODE,
          className: "uppercase",
          type: "readonly",
          defaultValue: marketCode,
          width: 215,
        },
        noPayReason: {
          label: LABEL_NO_PAY_REASON,
          className: "uppercase",
          type: "readonly",
          defaultValue: isGroup
            ? grpUnpaidCode?.length
              ? masterData.noPayReasons[grpUnpaidCode]
              : ""
            : noPayReason?.length
            ? masterData.noPayReasons[noPayReason]
            : "",
          width: 255,
        },
        adjustCode: {
          label: LABEL_ADJ_CODE,
          type: "readonly",
          defaultValue: adjustCode,
          width: 235,
        },
        roomRevenue: {
          label: TT_TABLE_HEADER_REVENUE.toUpperCase(),
          type: "readonly",
          defaultValue: roomRevenue,
          width: 215,
        },
        propertyAmount: {
          label: TABLE_HEADER_PROPERTY_AMOUNT.toUpperCase(),
          type: "readonly",
          defaultValue: propertyAmount,
          width: 255,
        },
        commTax: {
          label: LABEL_COMMISSION_TAX,
          type: "readonly",
          defaultValue: commTax,
          width: 235,
        },
        folioIndicator: {
          label: SS_FILTER_TITLE_FOLIO,
          type: "readonly",
          defaultValue: folioIndicator,
          width: 215,
        },
        propertyRatePercentage: {
          label: LABEL_COMMISSION_RATE,
          type: "readonly",
          defaultValue: propertyRatePercentage,
          width: 255,
        },
        commTaxPercentage: {
          label: LABEL_COMMISSION_TAX_RATE,
          type: "readonly",
          defaultValue: commTaxPercentage,
          width: 235,
        },
        psIndicator: {
          label: LABEL_PROCESSED,
          type: "readonly",
          displayValue: psIndicator === STAY_SUMMERY_PS ? YES_KEY : "",
          defaultValue: psIndicator,
          width: 125,
        },
        shareIndicator: {
          label: LABEL_SHARE_INDICATOR,
          type: "readonly",
          defaultValue: shareIndicator,
          width: 172,
        },
        contiguosIndicator: {
          label: LABEL_CONTIGUOUS_INDICATOR,
          type: "readonly",
          defaultValue: contiguosIndicator,
          width: 220,
        },
        invoiceIndicator: {
          label: SS_FILTER_TITLE_INVOICE_INDICATOR,
          type: "readonly",
          defaultValue: invoiceIndicator,
          width: 172,
        },
        bookingDate: {
          label: LABEL_BOOKING_DATE,
          type: "readonly",
          defaultValue: bookingDate,
          width: 125,
        },
        bookingSource: {
          label: LABEL_BOOKING_SOURCE,
          className: "uppercase",
          defaultValue: bookingSource,
          type: "readonly",
          width: 172,
        },
      },
    };
    if (countryCode !== US_COUNTRY_CODE) {
      configData.form = addToObject(
        configData.form,
        "roomRateTax",
        {
          label: LABEL_ROOM_RATE_TAX_AMT,
          type: "readonly",
          defaultValue: roomRateTax,
          width: 210,
        },
        9
      );
    }
    return configData;
  }, [editStayReviewStatus]);

  const handleBackToEdit = () => {
    dispatch(enableEditStayReviewMode(false));
  };

  const handleSubmit = (data) => {
    setDisableSave(true);
    dispatch(
      saveEditStay({
        ...payloadForEditSave,
        ...{
          reviewStayActionDetails: {
            ...payloadForEditSave.reviewStayActionDetails,
            roomRate:
              payloadForEditSave?.reviewStayActionDetails?.roomRate.replace(
                /,/g,
                ""
              ),
            roomRevenue:
              payloadForEditSave?.reviewStayActionDetails?.roomRevenue.replace(
                /,/g,
                ""
              ),
          },
        },
        ...{
          transientStay: pathname.includes(GROUP_ROUTE_PATH) ? false : true,
          userId: userInfo?.EID,
          userInitials: userInfo?.userInitials,
        },
      })
    );
  };

  // const handleErrors = (errors) => {
  //   setValidationErrors(errors);
  // };

  return (
    <div className="add-stay-enter-details pt-2">
      <h5 className="weight-700">{REVIEW_STAY_DETAILS}</h5>
      <FormStaySummary
        config={config}
        submitCallBack={handleSubmit}
        disableSubmitAction={disableSave}
        handleAddStayClose={handleEditStayClose}
        handleBackToEdit={handleBackToEdit}
        applyBtnTitle={STAY_SAVE_BTN_LABEL}
        enableReviewAction={editStayReviewMode}
        className={`modify-form-stay review-mode step-2-stay p-0`}
        mode="onChange"
        alertMessage={editStaySaveErrorMessage}
        errorMessage={editStaySaveAPIError}
        //formErrorCallback={handleErrors}
        //acrs={acrs}
      />
    </div>
  );
}
