import { useDispatch, useSelector } from "react-redux";

import { useEffect, useMemo, useState } from "react";
import {
  ADD_ADJUSTMENT_FAILED,
  ADD_ADJUSTMENT_TITLE,
  ADD_ADJ_ERROR_MSG_ADJUSTMENT_RATE_REQUIRED,
  ADD_ADJ_ERROR_MSG_COMMISSION_AMOUNT,
  ADJUSTED_COMMISSION_AMOUNT,
  ADJUSTED_COMMISSION_REVENUE,
  ADJUSTMENT_COMMISSION_TAX,
  ADJUSTMENT_DESCRIPTION,
  ADJUSTMENT_RATE_PER_ROOM_NIGHT,
  ADJUSTMENT_REASON,
  ADJUSTMENT_REASON_VALIDATION_REQUIRED,
  ADJ_BATCH_ID,
  ADVANCED_COMMISSION_AMOUNT,
  COMMISSION_RATE,
  ERROR_MSG_VALIDATION_ALLOWED_NUMBERS,
  LABEL_NO_PAY_REASON,
  LABEL_ROOM_NIGTHS,
  MAX_ADJ_COM_AMT_LIMIT_MSG,
  MAX_ADJ_ROOM_LIMIT_MSG,
  REVIEW_ADJUSTMENT_TITLE,
  REVIEW_BTN,
  SAVE_BTN,
  SELECT_REASON_ADJUSTMENT,
  SS_ERROR_MSG_ROOMNIGHTS_REQUIRED,
  SS_ERROR_MSG_ROOMRATE_AMOUNT_VALIDATE,
  TAX_RATE,
} from "../../../../utils/constants";

import "./addadjustment.scss";
import ModalDialog from "../../../../components/modal/ModalDialog";
import {
  convertObjToArray,
  rateValidation,
  formatAmount,
} from "../../../../utils/utils";
import AddAdjustmentForm from "../../../../components/formAddAdjustment/AddAdjustmentForm";
import {
  disableAdjustmentAction,
  reviewAdjustment,
  reviewAdjustmentError,
  saveAdjustment,
  saveAdjustmentFailed,
  updateAdjustmentReview,
  validateIATA,
} from "../../../../store/feature/groupblock/groupBlockSlice";
import {
  ADJUSTMENT_NO_PAY_REASON,
  ADJUSTMENT_REASON_OPTIONS,
  ADJUSTMENT_REASON_SHORT_DESC,
  ADJ_REASON_APAD,
  ADJ_REASON_CPAD,
  ADJ_REASON_PKAD,
  ADJ_REASON_RBAD,
  MAX_ADJ_COM_AMT_LIMIT,
  MAX_ADJ_ROOM_LIMIT,
  YES_KEY,
} from "../../../../utils/config";

export default function AddAdjustment({
  modalShow,
  handleAddAdjustmentClose,
  rowData,
}) {
  const { userInfo } = useSelector((state) => state.landing);
  const {
    propertyInfo,
    adjustmentReviewDetails,
    iataDetail,
    addAdjustmentFailed,
    reviewAdjustmentFailed,
    disableAdjustmentSave,
  } = useSelector((state) => state.group);
  const dispatch = useDispatch();
  const [adjustmentReason, setAdjustmentReason] = useState();
  const [roomNight, setRoomNight] = useState();
  const [reviewMode, setReviewMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [advCommissionAmt, setAdvCommissionAmt] = useState();
  const [adjustRate, setAdjustRate] = useState();
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    return () => {
      dispatch(saveAdjustmentFailed(false));
      dispatch(reviewAdjustmentError(false));
      dispatch(updateAdjustmentReview({}));
    };
  }, []);

  useEffect(() => {
    if (
      adjustmentReviewDetails &&
      Object.keys(adjustmentReviewDetails).length
    ) {
      setReviewMode(true);
      setFormData(adjustmentReviewDetails.reviewDetails);
      dispatch(
        validateIATA(adjustmentReviewDetails?.reviewDetails?.grpIataNumber)
      );
    }
  }, [adjustmentReviewDetails]);

  const clearError = () => {
    dispatch(reviewAdjustmentError(false));
  };

  useEffect(() => {
    setAdvCommissionAmt(formData.advCommissionAmt);
    setAdjustRate(formData.adjustRate);
    setRoomNight(formData.roomNights);
  }, [formData.advCommissionAmt, formData.adjustRate, formData.roomNights]);

  const config = useMemo(() => {
    const disableCommissionAmt =
      adjustmentReason === ADJ_REASON_RBAD ||
      adjustmentReason === ADJ_REASON_PKAD ||
      adjustmentReason === ADJ_REASON_CPAD;

    const disableRoomNightAndRate = adjustmentReason === ADJ_REASON_APAD;
    const adjustmentReasonOption = () => {
      const option = { ...ADJUSTMENT_REASON_OPTIONS };
      if (rowData.fpIndicator === YES_KEY) {
        delete option.APAD;
      }
      if (rowData.insideList) {
        rowData.insideList.forEach((insideBlock) => {
          if (insideBlock.adjustCode && option[insideBlock.adjustCode]) {
            delete option[insideBlock.adjustCode];
          }
        });
      }
      return option;
    };

    const allowOnlyAmount = (value) => {
      return value.replace(/[^0-9.,]/g, "");
    };

    const rateValidationAdjustment = (value) => {
      let regexNumbersOnly = new RegExp("^[0-9.,]+$"),
        returnVal;

      if (regexNumbersOnly.test(value)) {
        if (Number(value.replace(".", "").replace(/,/g, "")) > 0) {
          returnVal = true;
        } else {
          returnVal = SS_ERROR_MSG_ROOMRATE_AMOUNT_VALIDATE;
        }
      }
      return returnVal;
    };

    let data = {
      form: {
        adjustCode: {
          label: adjustmentReason
            ? ADJUSTMENT_REASON
            : SELECT_REASON_ADJUSTMENT,
          placeholder: "Select",
          type: reviewMode ? "readonly" : "selectv2",
          options: convertObjToArray(adjustmentReasonOption()),
          fieldClassName: "flex-full-width me-0",
          className: "w-250",
          rules: {
            required: true,
            message: ADJUSTMENT_REASON_VALIDATION_REQUIRED,
            validate: (value) => {
              setAdjustmentReason(value);
              clearError();
            },
          },
        },
      },
      secondaryBlock: {},
    };
    if (reviewMode) {
      data.form.adjustCode.defaultValue = formData?.adjustReasonDesc;
    }
    if (adjustmentReason) {
      const allowOnlyNumber = (value) => {
        return value.replace(/[^0-9]/g, "");
      };
      data.form.roomNights = {
        label: LABEL_ROOM_NIGTHS,
        type: reviewMode ? "readonly" : "text",
        disabled: disableRoomNightAndRate,
        defaultValue: roomNight,
        width: 195,
        onKeyup: (value) => {
          setRoomNight(Number(allowOnlyNumber(value)));
        },
        rules: {
          required: disableRoomNightAndRate ? false : true,
          message: SS_ERROR_MSG_ROOMNIGHTS_REQUIRED,
          validate: disableRoomNightAndRate
            ? () => {}
            : (value) => {
                clearError();
                const regexNumbersOnly = new RegExp("^[0-9]+$");
                setRoomNight(value);
                if (regexNumbersOnly.test(value)) {
                  if (Number(value) <= MAX_ADJ_ROOM_LIMIT) {
                    return true;
                  } else {
                    return `${MAX_ADJ_ROOM_LIMIT_MSG} ${MAX_ADJ_ROOM_LIMIT}`;
                  }
                }
              },
        },
      };

      data.form.adjustRate = {
        label: ADJUSTMENT_RATE_PER_ROOM_NIGHT,
        type: reviewMode ? "readonly" : "text",
        defaultValue: adjustRate,
        disabled: disableRoomNightAndRate,
        width: 260,
        precision: rowData.precision,
        onKeyup: (value) => {
          setAdjustRate(allowOnlyAmount(value));
        },
        onBlur: (val) => {
          const formatedValue = val.replace(/,/g, "");
          if (!isNaN(Number(formatedValue))) {
            let rate = formatAmount(formatedValue, rowData.precision);
            setAdjustRate(rate);
          }
        },
        rules: {
          required: disableRoomNightAndRate ? false : true,
          message: ADD_ADJ_ERROR_MSG_ADJUSTMENT_RATE_REQUIRED,
          validate: disableRoomNightAndRate
            ? () => {}
            : (value) => {
                clearError();
                setAdjustRate(value);
                const amountValidate = rateValidationAdjustment(value);
                return amountValidate;
              },
        },
      };

      data.form.advCommissionAmt = {
        label: ADVANCED_COMMISSION_AMOUNT,
        type: reviewMode ? "readonly" : "text",
        defaultValue: advCommissionAmt,
        disabled: disableCommissionAmt,
        fieldClassName: "me-0",
        width: 240,
        sectionClassName: "adv_com_amt",
        precision: rowData.precision,
        onKeyup: (val) => {
          setAdvCommissionAmt(allowOnlyAmount(val));
        },
        onBlur: (val) => {
          const formatedValue = val.replace(/,/g, "");
          if (!isNaN(Number(formatedValue))) {
            let rate = formatAmount(formatedValue, rowData.precision);
            setAdvCommissionAmt(rate);
          }
        },
        rules: {
          required: disableCommissionAmt ? false : true,
          message: ADD_ADJ_ERROR_MSG_COMMISSION_AMOUNT,
          validate: disableCommissionAmt
            ? () => {}
            : (value) => {
                clearError();
                setAdvCommissionAmt(value);
                const amountValidate = rateValidationAdjustment(value);
                if (amountValidate === true) {
                  if (Number(value) <= MAX_ADJ_COM_AMT_LIMIT) {
                    return amountValidate;
                  } else {
                    return `${MAX_ADJ_COM_AMT_LIMIT_MSG} ${MAX_ADJ_COM_AMT_LIMIT}`;
                  }
                } else {
                  return amountValidate;
                }
              },
        },
      };
      data.secondaryBlock.hr1 = {
        type: "hr",
      };
      data.secondaryBlock.noPayReason = {
        placeholder: "Select",
        label: LABEL_NO_PAY_REASON,
        type: reviewMode ? "readonly" : "selectv2",
        defaultValue: formData?.noPayReason,
        fieldClassName: "flex-full-width",
        options: convertObjToArray(ADJUSTMENT_NO_PAY_REASON, "keyValue"),
      };
    }
    if (formData && Object.keys(formData).length) {
      /* Review Mode */
      data.secondaryBlock.hr2 = {
        type: "hr",
      };
      data.secondaryBlock.adjustRevenue = {
        label: ADJUSTED_COMMISSION_REVENUE,
        type: "readonly",
        defaultValue: formData?.adjustRevenue,
        width: 230,
      };
      data.secondaryBlock.adjustCommissionAmt = {
        label: ADJUSTED_COMMISSION_AMOUNT,
        type: "readonly",
        defaultValue: formData?.adjustCommissionAmt,
        width: 230,
      };
      data.secondaryBlock.adjustReasonDesc = {
        label: ADJUSTMENT_DESCRIPTION,
        type: "readonly",
        defaultValue: formData?.adjustDesc,
        fieldClassName: "me-0",
        width: 215,
      };
      data.secondaryBlock.commRate = {
        label: COMMISSION_RATE,
        type: "readonly",
        defaultValue: formData?.commRate,
        width: 230,
      };
      data.secondaryBlock.taxRate = {
        label: TAX_RATE,
        type: "readonly",
        defaultValue: formData?.taxRate,
        width: 230,
      };
      data.secondaryBlock.adjustCommissionTax = {
        label: ADJUSTMENT_COMMISSION_TAX,
        type: "readonly",
        defaultValue: formData?.adjustCommissionTax,
        fieldClassName: "me-0",
        width: 215,
      };
      data.secondaryBlock.hr3 = {
        type: "hr",
      };
      const batchNum1 = formData?.batchNum1
        ? formData?.batchNum1.padStart(4, "0")
        : null;
      const batchNum2 = formData?.batchNum2
        ? formData?.batchNum2.padStart(4, "0")
        : null;
      data.secondaryBlock.batchId = {
        label: ADJ_BATCH_ID,
        type: "readonly",
        defaultValue: `${formData?.batchId.substring(
          0,
          1
        )}${batchNum1}${batchNum2}`,
        fieldClassName: "flex-full-width",
      };
    }
    return data;
  }, [adjustmentReason, formData, advCommissionAmt, adjustRate, roomNight]);

  const handleSubmit = (data) => {
    dispatch(disableAdjustmentAction(true));
    const {
      propertyCode,
      eventStartDate,
      eventEndDate,
      groupIataNumber,
      groupBlockId,
      precision,
    } = rowData;
    const grpIataNumber = groupIataNumber.trim().length
      ? groupIataNumber
      : adjustmentReviewDetails?.reviewDetails?.grpIataNumber;
    const { propertyDetail } = propertyInfo[propertyCode];
    if (formData && Object.keys(formData).length) {
      const {
        adjustDesc,
        advCommissionAmt,
        noPayReason,
        batchNum1,
        batchNum2,
        taxAvailable,
        adjCommissionDetails,
      } = formData;
      dispatch(
        saveAdjustment({
          propertyCode,
          requestParam: {
            adjustDesc,
            eventEndDate,
            advCommissionAmt: formatAmount(advCommissionAmt, NaN),
            noPayReason,
            batchNum1,
            batchNum2,
            taxAvailable,
            adjCommissionDetails,
            propDetail: propertyDetail,
            iataDetail: iataDetail,
            userId: userInfo?.EID,
            userInitials: userInfo?.userInitials,
            grpIataNumber,
            precision,
          },
        })
      );
    } else {
      dispatch(
        reviewAdjustment({
          propertyCode,
          groupRowDetail: rowData,
          requestParam: {
            ...{
              noPayReason: "",
              adjustReasonDesc: ADJUSTMENT_REASON_OPTIONS[data.adjustCode],
              shortDesc: ADJUSTMENT_REASON_SHORT_DESC[data.adjustCode],
              grpIataNumber,
              eventStartDate: eventStartDate,
              eventEndDate: eventEndDate,
              mhSequenceNumber: groupBlockId,
              acrsConvDate: propertyDetail?.acrsConvDate,
              userId: userInfo?.EID,
              userInitials: userInfo?.userInitials,
            },
            ...data,
            ...{
              roomNights: data.roomNights || "0",
              adjustRate: data.adjustRate
                ? formatAmount(data.adjustRate, rowData.precision)
                : formatAmount("0", rowData.precision),
              advCommissionAmt: data.advCommissionAmt
                ? formatAmount(data.advCommissionAmt, rowData.precision)
                : formatAmount("0", rowData.precision),
              roomRateInd: propertyDetail.roomRateInd || "",
              precision,
            },
          },
        })
      );
    }
  };

  const adjErrorMessage = useMemo(() => {
    if (reviewAdjustmentFailed) {
      return reviewAdjustmentFailed;
    } else if (addAdjustmentFailed) {
      return ADD_ADJUSTMENT_FAILED;
    } else {
      return "";
    }
  }, [reviewAdjustmentFailed, addAdjustmentFailed]);

  const handleIsDirty = (flag) => {
    setIsDirty(flag);
  };

  return (
    <ModalDialog
      show={modalShow}
      handleOnShowClose={handleAddAdjustmentClose}
      size={"lg"}
      ariaLabelledby="contained-modal-title-vcenter"
      className={`add-adjustment-overlay overlay-fixed-min-width`}
    >
      <div className="add-adjustment">
        <h4 className="weight-700">
          {formData && Object.keys(formData).length
            ? REVIEW_ADJUSTMENT_TITLE
            : ADD_ADJUSTMENT_TITLE}
        </h4>
        <AddAdjustmentForm
          config={config}
          submitCallBack={handleSubmit}
          disableSubmitAction={disableAdjustmentSave}
          applyBtnTitle={
            formData && Object.keys(formData).length ? SAVE_BTN : REVIEW_BTN
          }
          handleAddStayClose={handleAddAdjustmentClose}
          // applyBtnTitle={applyBtnTitle}
          className={`modify-form-stay p-0 ${reviewMode ? "review-mode" : ""}`}
          mode="onChange"
          warningMessage={formData?.warningMessage}
          // alertMessage={editSaveErrorMessage}
          errorMessage={`${adjErrorMessage}`}
          // readOnly={!rowData.enableEdit}
          isDirtyCallback={handleIsDirty}
        />
      </div>
    </ModalDialog>
  );
}
