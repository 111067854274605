/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import {
  fetchTransientBatchOverview,
  fetchtransientBlock,
  updateTransientTableParams,
  updateTBStatus,
  tBReviewApproveSuccess,
  tBReviewApproveFailed,
} from "../../store/feature/transient/transientSlice";
import TransientBatchOverview from "../../components/transientbatchoverview/TransientBatchOverview";
import DataTableServerSide from "../../components/datatableserverside/DataTableServerSide";
import TransientFilter from "./component/transientfilter/TransientFilter";
import LoadingBar from "../../components/loadingbar/LoadingBar";
import InfoBar from "../../components/infobar/InfoBar";

import {
  PROPERTY_CODE,
  BATCH_DATE,
  BATCH_ID,
  STAYS,
  VIEW_STAYS,
  APPROVER,
  PROPERTY_AMOUNT,
  CURRENCY,
  AUTO_APPROVE,
  REV_DATE,
  APPR_DATE,
  STATUS,
  MOD,
  REVIEWER,
  BATCH_OVERVIEW_TYPE,
  LANDING_NO_DATA_FOUND,
  BATCH_STATUS_NAME,
  DOWNLOAD_EXPORT_LABEL,
  ACTION_BAR_BTN_NAME,
  REVIEW_APPROVE_SUCCESS_MSG,
  TB_TABLE_BATCH_SELECTED,
  SYSTEM_APPROVED,
  APPROVE_SUCCESS_MSG,
  CURRENCY_TYPE,
  MOD_TOOLTIP_LABEL,
  ERROR_MESSAGE,
  CTAC_SUPPORT,
} from "../../utils/constants";
import {
  buildApproveReviewPayload,
  buildExportURL,
  formatDate,
  scrollToTop,
  validatePrivilege,
} from "../../utils/utils";
import Button from "react-bootstrap/Button";
import "./transient.scss";
import userIcon from "../../assets/user-icon.png";
import ActionBar from "../../components/actionbar/ActionBar";
import { API_URL } from "../../utils/Api_URL";
import CurrencyButton from "../../components/currencybutton/CurrencyButton";
import {
  getDownloadFile,
  updateCurrancy,
} from "../../store/feature/landing/landingSlice";
import Pagination from "../../components/pagination/Pagination";
import {
  TB_DEFAULT_TABLE_PAYLOAD,
  TB_STATUS_APPROVE,
  TB_STATUS_PENDING,
  AUTO_APPROVAL_ID,
  TRANSIENT_VIEW_STAYS_LINK,
  CURRENCY_CONST_PROPERTY,
  CURRENCY_CONST_USD,
  USER_ACCESS_LEVEL,
  GROUP_ROUTE_PATH,
} from "../../utils/config";
import ErrorText from "../../components/ErrorText";
import {
  renderCellBatchID,
  renderCellBatchStatus,
  renderCellPropAmount,
} from "../../utils/renderFunction";
import { useAlertMsg } from "../../utils/context/alertMessageContext";

const renderCellBatchDate = ({ row }) =>
  row?.original?.batchDate
    ? formatDate(row?.original?.batchDate, "DDMMMYY").toUpperCase()
    : null;

const renderCellViewStay = (row, q50Details) => {
  const stayCount = Number(row.stays);
  if (stayCount || (q50Details && q50Details[row?.propertyCode])) {
    return (
      <Link
        className="view-stays"
        to={`${TRANSIENT_VIEW_STAYS_LINK}${row?.propertyCode}/${row?.batchNum1}/${row?.batchNum2}`}
      >
        {VIEW_STAYS}
      </Link>
    );
  } else {
    return <span className="view-stays disabled_link">{VIEW_STAYS}</span>;
  }
};

const renderCellAutoApprovalDate = ({ row }) =>
  row?.original?.autoApprovalDate
    ? formatDate(row?.original?.autoApprovalDate, "DDMMMYY").toUpperCase()
    : null;

const renderCellReviewDate = ({ row }) =>
  row?.original?.reviewDate
    ? formatDate(row?.original?.reviewDate, "DDMMMYY").toUpperCase()
    : null;

export const renderCellApprover_Reviewer = (row, key) =>
  row?.original[`${key}Id`] ? (
    <OverlayTrigger
      delay={{ show: 50, hide: 400 }}
      placement="left"
      overlay={
        <Popover className="pop-over">
          <div className="font-12">
            <span>{row.original[`${key}Name`]}</span>
          </div>
        </Popover>
      }
    >
      <img
        width="18"
        height="18"
        alt={row.original[`${key}Name`]}
        src={userIcon}
      />
    </OverlayTrigger>
  ) : null;

const renderCellApprovalDate = ({ row }) =>
  row?.original?.approvalDate
    ? formatDate(row?.original?.approvalDate, "DDMMMYY").toUpperCase()
    : null;

const NoDataFound = ({ hasData }) => {
  return (
    <>
      {!hasData?.length ? (
        <p className="ms-2">{LANDING_NO_DATA_FOUND}</p>
      ) : null}
    </>
  );
};

const Loader = ({ show }) => {
  return <>{show ? <LoadingBar global /> : null}</>;
};

export default function Transient() {
  const { currencyType, propCode, userInfo, propertyDetails, q50Details } =
    useSelector((state) => state.landing);

  const {
    block,
    transientBatchOverview,
    payloadForTable,
    loadingTransientOverview,
    loadingTransientBlock,
    transientTotalCount,
    reviewApproveSuccess,
    reviewApproveFailed,
    overviewInternalError,
    tableInternalError,
    serverError,
  } = useSelector((state) => state.transient);
  const {
    alertContextState: { transReviewApproveFail, transReviewApproveSuccess },
    alertContextDispatch,
  } = useAlertMsg();
  const { type, currencyCode } = useParams();
  const dispatch = useDispatch();
  const [transientStateData, setTransientStateData] = useState([]);
  const [isSelectAllDisabled, setIsSelectAllDisabled] = useState(false);
  const [selectedRowStatus, setSelectedRowStatus] = useState("");
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [resetSelection, setResetSelection] = useState(false);
  const [actionStatus, setActionStatus] = useState("");
  let { state: filterByPayload, pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      alertContextDispatch({
        type: "reviewApproveFailedHide",
      });

      alertContextDispatch({
        type: "transReviewApproveSuccessHide",
      });

      alertContextDispatch({
        type: "transServerErrorHide",
      });

      alertContextDispatch({
        type: "transTableInternalErrorHide",
      });
    };
  }, []);

  const [selectedRows, setSelectedRows] = useState([{}]);
  const updateTBListParam = (params) => {
    dispatch(
      updateTransientTableParams({
        ...payloadForTable,
        ...params,
        group: pathname.includes(GROUP_ROUTE_PATH) ? true : false,
      })
    );
  };
  useEffect(() => {
    updateTBListParam(
      filterByPayload
        ? { ...TB_DEFAULT_TABLE_PAYLOAD, ...{ filterBy: filterByPayload } }
        : { ...TB_DEFAULT_TABLE_PAYLOAD }
    );
    filterByPayload && navigate("/transient", { replace: true });
  }, []);

  useEffect(() => {
    if (reviewApproveSuccess) {
      const actualCount =
        Number(transientTotalCount) - Number(selectedRowCount);
      const pageNumber =
        Number(payloadForTable.pageNumber) > 1
          ? Number(payloadForTable.pageNumber) - 1
          : 1;
      const expectedCount = pageNumber * Number(payloadForTable.pageSize);
      // logic to reset pagination if total record lessthan pagesize
      if (
        Number(payloadForTable.pageNumber) > 1 &&
        actualCount <= expectedCount
      ) {
        updateTBListParam({ ...payloadForTable, pageNumber: 1 });
        refreshTBOverview();
      } else {
        refreshTBTableData();
        refreshTBOverview();
      }
    }
  }, [reviewApproveSuccess, reviewApproveFailed]);

  // Show ReviewApprove Failed Msg
  useEffect(() => {
    if (reviewApproveFailed) {
      alertContextDispatch({
        type: "reviewApproveFailedShow",
        payload: {
          message: `${ERROR_MESSAGE} ${CTAC_SUPPORT} `,
          variant: "danger",
        },
      });
    }
  }, [reviewApproveFailed]);

  const hideReviewApproveError = () => {
    dispatch(tBReviewApproveFailed(false));
  };

  useEffect(() => {
    if (!transReviewApproveFail.show) {
      hideReviewApproveError();
    }
  }, [transReviewApproveFail]);

  // Show Review Approve success Msg
  useEffect(() => {
    if (reviewApproveSuccess) {
      alertContextDispatch({
        type: "transReviewApproveSuccessShow",
        payload: {
          actionStatus,
        },
      });
    }
  }, [reviewApproveSuccess]);

  const hideReviewApproveSuccess = () => {
    dispatch(tBReviewApproveSuccess(false));
  };

  useEffect(() => {
    if (!transReviewApproveSuccess.show) {
      hideReviewApproveSuccess();
    }
  }, [transReviewApproveSuccess]);

  useEffect(() => {
    if (type) {
      const defaultFilter = {
        ...TB_DEFAULT_TABLE_PAYLOAD,
        filterBy: {
          ...TB_DEFAULT_TABLE_PAYLOAD.filterBy,
          status: [type?.toUpperCase()],
        },
      };
      updateTBListParam(defaultFilter);
    }
  }, [type]);

  const makeData = (block, rowSelection) => {
    if (rowSelection?.length > 0) {
      setSelectedRowStatus(rowSelection[0]?.batchStatus);
      return block.map((data) => ({
        ...data,
        uniqueId: `batch-${data.batchNum1.padStart(
          4,
          "0"
        )}${data.batchNum2.padStart(4, "0")}`,
        isDisabled: validatePrivilege(
          data,
          userInfo?.authIndicator,
          userInfo?.EID,
          rowSelection[0]
        ),
      }));
    }
    return block.map((data) => ({
      ...data,
      uniqueId: `batch-${data.batchNum1.padStart(
        4,
        "0"
      )}${data.batchNum2.padStart(4, "0")}`,
      isDisabled: validatePrivilege(
        data,
        userInfo?.authIndicator,
        userInfo?.EID
      ),
    }));
  };

  const checkIsSelectAllDisabled = (block) => {
    let disableCheckbox = false;
    let blockArray = block
      ? block.map((item) => {
          return item.batchStatus.toUpperCase();
        })
      : [];
    const noOfUniqueStatuses = [...new Set(blockArray)].length;
    if (userInfo?.authIndicator <= USER_ACCESS_LEVEL.READ_ONLY) {
      disableCheckbox = true;
    } else if (userInfo?.authIndicator === USER_ACCESS_LEVEL.REVIEW_ONLY) {
      if (noOfUniqueStatuses > 1) {
        disableCheckbox = true;
      } else if (!blockArray.includes(BATCH_STATUS_NAME.REVIEW)) {
        disableCheckbox = true;
      }
    } else if (
      userInfo?.authIndicator >= USER_ACCESS_LEVEL.REVIEW_AND_APPROVE
    ) {
      if (noOfUniqueStatuses > 1) {
        disableCheckbox = true;
      } else if (noOfUniqueStatuses === 1) {
        if (
          !(
            blockArray.includes(BATCH_STATUS_NAME.PENDING) ||
            blockArray.includes(BATCH_STATUS_NAME.REVIEW)
          )
        ) {
          disableCheckbox = true;
        } else {
          disableCheckbox = block.some((data) => {
            return data.reviewerId === userInfo.EID;
          });
          return disableCheckbox;
        }
      }
    }
    return disableCheckbox;
  };

  useEffect(() => {
    setResetSelection(!resetSelection);
    const makeDataOutput = makeData(block, selectedRows);
    const isSelectAllDisabled = checkIsSelectAllDisabled(block);
    setIsSelectAllDisabled(isSelectAllDisabled);
    setTransientStateData(makeDataOutput);
  }, [block]);
  let batchTableTimer;
  const refreshTBTableData = () => {
    let tablePayload = {
      ...payloadForTable,
      currencyType: currencyType,
    };
    if (propCode) {
      dispatch(fetchtransientBlock({ tablePayload, propCode }));
      if (batchTableTimer) {
        clearTimeout(batchTableTimer);
      }
    }
  };

  const refreshTBOverview = () => {
    const transientOverviewReqBody = {
      currencyType: currencyType.toLowerCase(),
      type: BATCH_OVERVIEW_TYPE.TRANSIENT_BATCH,
      filterBy: payloadForTable.filterBy,
    };
    if (propCode) {
      dispatch(fetchTransientBatchOverview(propCode, transientOverviewReqBody));
    }
  };

  useEffect(() => {
    if (batchTableTimer) {
      clearTimeout(batchTableTimer);
    }
    batchTableTimer = setTimeout(() => {
      refreshTBTableData();
    }, 50);
    return () => {
      if (batchTableTimer) {
        clearTimeout(batchTableTimer);
      }
    };
  }, [payloadForTable, propCode, userInfo]);

  useEffect(() => {
    if (!currencyCode) {
      dispatch(updateCurrancy(CURRENCY_CONST_PROPERTY));
    } else if (currencyCode === CURRENCY_CONST_USD) {
      dispatch(updateCurrancy(CURRENCY_CONST_USD));
    }
  }, [propCode]);

  let batchOverviewTimer;
  useEffect(() => {
    if (batchOverviewTimer) {
      clearTimeout(batchOverviewTimer);
    }
    batchOverviewTimer = setTimeout(() => {
      refreshTBOverview();
    }, 50);
    return () => {
      if (batchOverviewTimer) {
        clearTimeout(batchOverviewTimer);
      }
    };
  }, [propCode, payloadForTable.filterBy]);

  const selectedRowCallback = (data) => {
    setSelectedRowCount(data?.length);
    setSelectedRows(data);
    const makeDataOp = makeData(transientStateData, data);
    setTransientStateData(makeDataOp);
  };

  const column = useMemo(
    () => [
      {
        accessorKey: "propertyCode",
        header: () => PROPERTY_CODE,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "batchId",
        header: () => BATCH_ID,
        sortDescFirst: false,
        cell: renderCellBatchID,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "batchDate",
        header: () => BATCH_DATE,
        footer: (props) => props.column.id,
        cell: renderCellBatchDate,
      },
      {
        accessorKey: "stays",
        header: () => STAYS,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "View Stays",
        header: () => VIEW_STAYS,
        cell: ({ row }) => renderCellViewStay(row.original, q50Details),
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        accessorKey: "propertyAmount",
        header: () => PROPERTY_AMOUNT,
        cell: ({ row }) => renderCellPropAmount(row, currencyType),
        className: "header_right_align",
        footer: (props) => props.column.id,
        currencyType,
      },
      {
        accessorKey: "currency",
        header: () => CURRENCY,
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <span>
            {currencyType === CURRENCY_TYPE.USD
              ? CURRENCY_TYPE.USD
              : row.original.currency}
          </span>
        ),
      },
      {
        accessorKey: "autoApprovalDate",
        header: () => AUTO_APPROVE,
        footer: (props) => props.column.id,
        cell: renderCellAutoApprovalDate,
      },
      {
        accessorKey: "batchStatus",
        header: () => STATUS,
        cell: renderCellBatchStatus,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "modIndicator",
        header: () => MOD,
        footer: (props) => props.column.id,
        tooltip: MOD_TOOLTIP_LABEL,
      },
      {
        accessorKey: "reviewDate",
        header: () => REV_DATE,
        footer: (props) => props.column.id,
        cell: renderCellReviewDate,
      },
      {
        accessorKey: "reviewerId",
        header: () => REVIEWER,
        cell: ({ row }) => renderCellApprover_Reviewer(row, "reviewer"),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "approvalDate",
        header: () => APPR_DATE,
        footer: (props) => props.column.id,
        cell: renderCellApprovalDate,
      },
      {
        accessorKey: "approverId",
        header: () => APPROVER,
        cell: ({ row }) => renderCellApprover_Reviewer(row, "approver"),
        footer: (props) => props.column.id,
      },
    ],
    [currencyType, q50Details]
  );

  const applySort = (sortBy) => {
    // sortBy = sortBy || TB_DEFAULT_TABLE_PAYLOAD.sortBy;
    if (sortBy) {
      updateTBListParam({ ...payloadForTable, sortBy: sortBy });
    }
  };

  const applyFilter = (filterBy) => {
    if ("modIndicator" in filterBy && filterBy.modIndicator[0] === undefined) {
      filterBy.modIndicator = [];
    }
    if ("status" in filterBy && filterBy.status[0] === undefined) {
      filterBy.status = [];
    }
    if ("month" in filterBy && filterBy.month === undefined) {
      filterBy.month = "";
    }

    updateTBListParam({
      filterBy: { ...payloadForTable.filterBy, ...filterBy },
      pageNumber: 1,
    });
  };

  const downloadXLS = () => {
    let fileURL = buildExportURL({
      url: `${API_URL.GET_TRANSIENT_BATCH_DETAILS_EXPORT}/${propCode}`,
      filterBy: payloadForTable.filterBy,
      currencyType: currencyType,
    });
    const fileName = `Transient Batch-${propCode}-${formatDate(
      new Date(),
      "DDMMMYY"
    )}`;
    dispatch(getDownloadFile(fileURL, fileName));
  };

  const onApprove = (actionStatus) => {
    if (actionStatus) {
      hideReviewApproveSuccess();
      hideReviewApproveError();
      setActionStatus(actionStatus);
      const updateTBReqBody = buildApproveReviewPayload(
        selectedRows,
        userInfo?.EID
      );
      const group = pathname.includes(GROUP_ROUTE_PATH) ? true : false;
      dispatch(updateTBStatus(actionStatus, { ...updateTBReqBody, group }));
    }
  };

  const onCancelClick = () => {
    setResetSelection(!resetSelection);
  };

  useEffect(() => {
    if (serverError || overviewInternalError) {
      alertContextDispatch({
        type: "transServerErrorShow",
      });
    }
  }, [serverError || overviewInternalError]);

  useEffect(() => {
    if (tableInternalError) {
      alertContextDispatch({
        type: "transTableInternalErrorShow",
      });
    }
  }, [tableInternalError]);

  return (
    <>
      <Loader show={loadingTransientOverview} />
      {transientBatchOverview &&
      transientBatchOverview !== null &&
      Object.keys(transientBatchOverview).length ? (
        <TransientBatchOverview
          data={transientBatchOverview}
          currencyType={currencyType}
          hasMultipleCurrency={transientBatchOverview?.multipleCurrency}
          className={"tb_summery_card"}
        />
      ) : (
        <p className="mt-3 ms-2"> {LANDING_NO_DATA_FOUND} </p>
      )}
      <TransientFilter filterCallback={applyFilter} />
      <hr />

      <div className="d-flex flex-row-reverse ms-1 mb-4">
        <div className="transient-currency">
          <CurrencyButton
            showUSD={propertyDetails.currencyCode !== CURRENCY_TYPE.USD}
          />
        </div>
        <button
          onClick={downloadXLS}
          className="download-btn btn btn-link text-decoration-none font-12 mt-auto py-0"
        >
          <i className="download-icon" /> <span>{DOWNLOAD_EXPORT_LABEL}</span>
        </button>
        {block?.length ? (
          <Pagination
            className="pagination-bar"
            showOnlyInfo={true}
            currentPage={payloadForTable.pageNumber}
            totalCount={transientTotalCount}
            pageSize={payloadForTable.pageSize}
          />
        ) : null}
      </div>
      <div className="transient-batch-table">
        <ActionBar
          //showActionBar={false}
          showActionBar={selectedRowCount !== 0}
          message={`${selectedRowCount} ${TB_TABLE_BATCH_SELECTED}`}
        >
          <div className=" bd-highlight">
            {selectedRowStatus &&
            selectedRowStatus.toUpperCase() === BATCH_STATUS_NAME?.REVIEW ? (
              <Button
                className="action-btn weight-400"
                onClick={() => onApprove(TB_STATUS_PENDING)}
                variant="outline-success"
              >
                {ACTION_BAR_BTN_NAME?.FOR_APPROVAL}
              </Button>
            ) : (
              <Button
                className="action-btn weight-400"
                onClick={() => onApprove(TB_STATUS_APPROVE)}
                variant="outline-success"
              >
                {ACTION_BAR_BTN_NAME?.FOR_APPROVE}
              </Button>
            )}
            <Button
              className="btn-link action-cancel-btn p-0"
              onClick={() => onCancelClick()}
              variant="outline-success"
            >
              {ACTION_BAR_BTN_NAME.CANCEL}
            </Button>
          </div>
        </ActionBar>
        {loadingTransientBlock ? <LoadingBar /> : null}
        <DataTableServerSide
          data={[...transientStateData]}
          column={column}
          sortCallback={applySort}
          enableCheckboxSelection={true}
          selectedRowCallback={selectedRowCallback}
          isSelectAllDisabled={isSelectAllDisabled}
          resetSelection={resetSelection}
        ></DataTableServerSide>
        {block?.length ? (
          <div>
            <Pagination
              className="pagination-bar"
              currentPage={payloadForTable.pageNumber}
              totalCount={transientTotalCount}
              pageSize={payloadForTable.pageSize}
              onItemPerPageChange={(pageSize, resetPage) => {
                scrollToTop(500);
                if (resetPage) {
                  updateTBListParam({
                    pageNumber: resetPage,
                    pageSize,
                  });
                } else {
                  updateTBListParam({
                    pageNumber: payloadForTable.pageNumber,
                    pageSize,
                  });
                }
              }}
              onPageChange={(page) => {
                scrollToTop(500);
                updateTBListParam({
                  pageNumber: page,
                  pageSize: payloadForTable.pageSize,
                });
              }}
            />
          </div>
        ) : null}
        {/* <DataTable
          data={[...transientStateData]}
          column={column}
          //sortCallback={applySort}
          enablePagination={true}
          enableCheckboxSelection={true}
          selectedRowCallback={selectedRowCallback}
          //isSelectAllDisabled={isSelectAllDisabled}
          //resetSelection={resetSelection}
        ></DataTable> */}
        <NoDataFound hasData={block} />
      </div>
    </>
  );
}
